import React from 'react'
import Image from 'gatsby-image'
import { Box, Heading, Text, Grid } from 'components'
import LeadCTAButton from 'components/LeadCTAButton'

const PageTitleWithImage = ({
  title,
  titleFontSize = 5,
  description,
  gatsbyFluidImage,
  ctaText = 'Jetzt anfragen',
  leadModalType = 'teilverkauf',
  ctaButtonID,
  shouldShowBrochureCTA = false,
  alt,
}) => {
  return (
    <Box
      sx={{
        height: [
          'calc(100vh - 64px)',
          'calc(100vh - 64px)',
          'calc(100vh - 64px)',
          'calc(100vh - 128px)',
        ],
        position: 'relative',
      }}
    >
      {gatsbyFluidImage && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <Image
            fluid={gatsbyFluidImage}
            loading="eager"
            style={{ height: '100%' }}
            {...(typeof title === 'string' && { alt: title })}
            {...(alt && { alt })}
          />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: ['center', 'center', 'center', 'flex-end'],
          alignItems: 'center',
          height: '100%',
          maxWidth: 1160,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            zIndex: 2,
            backgroundColor: 'white',
            borderRadius: 12,
            padding: 6,
            width: 500,
          }}
        >
          <Grid columns={[1]} gap={4} sx={{ alignSelf: 'start' }}>
            <Heading.H1 sx={{ color: 'darkNavy80', fontSize: titleFontSize }}>
              {title}
            </Heading.H1>
            {description && (
              <Text sx={{ color: 'darkNavy80' }}>{description}</Text>
            )}
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                flexDirection: ['column', null, 'row'],
              }}
            >
              <LeadCTAButton
                variant="v2Main1"
                ctaButtonID={ctaButtonID}
                leadModalType={leadModalType}
                sx={{
                  justifySelf: ['stretch', null, 'start'],
                  whiteSpace: 'nowrap',
                }}
              >
                {ctaText}
              </LeadCTAButton>
              {shouldShowBrochureCTA && (
                <LeadCTAButton
                  variant="v2Main1"
                  ctaButtonID={ctaButtonID}
                  leadModalType="brochure"
                  sx={{
                    justifySelf: ['stretch', null, 'start'],
                    whiteSpace: 'nowrap',
                  }}
                >
                  Infopaket anfordern
                </LeadCTAButton>
              )}
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default PageTitleWithImage
