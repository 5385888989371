import React from 'react'
import { useStaticQuery, graphql, Link as RouterLink } from 'gatsby'

import Layout from 'components/Layout'
import TeilverkaufCalculator from 'components/TeilverkaufCalculator'
import { Link, Text, Box } from 'components'

import PageTitleWithImage from 'screens/Home/components/PageTitleWithImage'
import PageTitleWithImageV3 from 'screens/Home/components/PageTitleWithImageV3'
import FeatureBoxesWithTitle from 'screens/Home/components/FeatureBoxesWithTitle'
import PageSnippets from 'screens/Home/components/PageSnippets'
import Testimonials from 'screens/Home/components/Testimonials'
import SimpleTextBanner from 'screens/Home/components/SimpleTextBanner'
import KnowUsFrom from 'screens/Home/components/KnowUsFrom'
import Blog from 'screens/Home/components/Blog'
import Map from 'screens/Home/components/Map'
import Flow from 'screens/Home/components/Flow'
import ContactFullWidth from 'screens/Home/components/ContactFullWidth'
import NavigationalElements from 'screens/Home/components/NavigationalElements'
import TVSituations from 'screens/Home/components/TVSituations'
import FAQTeilverkauf from 'screens/Home/components/FAQTeilverkauf'
import { REACT_APP_IS_TV_CALCULATOR_ENABLED } from 'config'

import { useLocation, useTranslate } from 'context/location'

/* Soft hyphenation is used to prevent forced breaking */
const PAGE_TITLE_WITH_IMAGE_CONTENT = (
  <>Ihr Partner für den Teilverkauf - Wir kaufen bis zu 50% Ihres Hauses</>
)
const Page = () => {
  const translate = useTranslate()
  const { countryCode } = useLocation()

  const {
    featuredArticlesDE: { articles: featuredArticlesDE },
    featuredArticlesAT: { articles: featuredArticlesAT },
    newHeroImage,
    contactFullWidthImage,
    salesImage,
    bambusHouseImage,
  } = useStaticQuery(
    graphql`
      query {
        featuredArticlesDE: contentfulFeaturedArticlesLayout(
          node_locale: { eq: "de" }
          slug: { eq: "teilverkauf-de" }
        ) {
          articles {
            ...Article
          }
        }
        featuredArticlesAT: contentfulFeaturedArticlesLayout(
          node_locale: { eq: "de" }
          slug: { eq: "teilverkauf-at" }
        ) {
          articles {
            ...Article
          }
        }
        newHeroImage: file(relativePath: { eq: "new-hero.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        contactFullWidthImage: file(
          relativePath: { eq: "business-man-1.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        salesImage: file(relativePath: { eq: "people-at-desk.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 560) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        bambusHouseImage: file(relativePath: { eq: "bambus-haus.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <Layout
      seo={{
        title: 'Teilverkauf: Hausteil verkaufen und Wohnrecht behalten',
        description:
          'Bambus.io kauft bis zu 50% Ihres Hauses an - Sie bleiben hr Leben lang drin wohnen! Teilverkauf mit Rückkaufsrecht. Jetzt unverbindlich anfragen!',
      }}
    >
      <Box
        sx={{
          display: ['none', 'block', 'block', 'block'],
        }}
      >
        <PageTitleWithImageV3
          title={PAGE_TITLE_WITH_IMAGE_CONTENT}
          gatsbyFluidImage={newHeroImage.childImageSharp.fluid}
          leadModalType="teilverkauf"
          ctaButtonID="Teilverkauf--PageTitleWithImage"
          titleFontSize="32px"
          alt="Mehr Liquidität und Lebensfreude dank Teilverkauf"
          description={
            <>
              Sie wohnen weiterhin lebenslang im Eigenheim und können den
              verkauften Anteil jederzeit wieder zurückkaufen. Schnell und
              unkompliziert.
            </>
          }
          shouldShowBrochureCTA
        />
      </Box>
      <Box
        sx={{
          display: ['block', 'none', 'none', 'none'],
        }}
      >
        <PageTitleWithImage
          title={PAGE_TITLE_WITH_IMAGE_CONTENT}
          gatsbyFluidImage={newHeroImage.childImageSharp.fluid}
          leadModalType="teilverkauf"
          ctaButtonID="Teilverkauf--PageTitleWithImage"
          titleFontSize="32px"
          alt="Mehr Liquidität und Lebensfreude dank Teilverkauf"
          description={
            <>
              Ihr Partner für finanzielle Flexibilität.
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Sie wohnen weiterhin im Eigenheim und können den verkauften
                Anteil jederzeit wieder zurückkaufen.
              </div>
            </>
          }
          shouldShowBrochureCTA
        />
      </Box>
      <SimpleTextBanner
        title="Unser Service"
        descriptionText={
          'Sie besitzen ein Einfamilienhaus, benötigen aber liquide Mittel und können nicht auf Ihr Vermögen zugreifen? Ein Immobilien-Teilverkauf ist die Lösung, um einen Teil des Eigenheims in bares Geld umzuwandeln.'
        }
        hideCTA
        textProps={{
          textAlign: 'center',
        }}
        backgroundColor="colorBlue2"
      />
      <KnowUsFrom />
      <NavigationalElements />
      <SimpleTextBanner
        title="Was ist ein Immobilien-Teilverkauf?"
        titleID="was-ist-ein-immobilien-teilverkauf"
        descriptionNode={
          <Text as="p">
            Durch einen Immobilien-Teilverkauf können Sie als Hausbesitzer
            gebundenes Vermögen Ihrer Immobilie freisetzen. Bambus kauft einen
            Teil (bis zu 50 %) Ihres Einfamilienhauses und zahlt Ihnen den Wert
            des verkauften Anteils aus. Mittels eines im Grundbuch eingetragenen
            Fruchtgenussrechts bleiben Sie zu 100% in Kontrolle und behalten das
            Recht, darin zu wohnen, solange Sie wollen. <br />
            <br />
            Sie bleiben flexibel und können den verkauften Teil des Hauses auch
            jederzeit wieder zurückkaufen. Nur für den Anteil, den Sie verkauft
            haben, verrechnet Ihnen Bambus eine klar definierte und faire
            Nutzungsgebühr.
          </Text>
        }
        hideCTA
        textProps={{
          textAlign: 'center',
        }}
      />
      <FeatureBoxesWithTitle
        title="Vorteile von Teilverkauf"
        subtitle="Flexibel und nachhaltig"
        showCTA
        ctaButtonID="vorteile-von-teilverkauf-main-page"
        leadModalType="teilverkauf_callback"
        ctaText="Jetzt Beratungsgespräch vereinbaren"
        ctaOtherProps={{
          variant: 'v2Main1',
          sx: {
            width: ['100%', 400],
            maxWidth: 500,
            mx: ['auto', 0],
          },
        }}
        items={[
          {
            title: 'Mehr Liquidität zum Leben',
            description:
              'Im Alter durch den Teilverkauf mehr Geld zur Verfügung haben.',
          },
          {
            title: 'Wohnhaft bleiben',
            description:
              'In Ihrer Immobilie wohnhaft bleiben und dieses Recht auf Lebzeiten sichern.',
          },
          {
            title: 'Individuelle Beratung',
            description:
              'Wir beraten Sie kostenlos und unverbindlich auch über Alternativlösungen wie Leibrente oder die Beleihung Ihrer Immobilie.',
          },
          {
            title: 'Vorsorge',
            description:
              'Durch den Teilverkauf sorgen Sie dafür, dass Sie oder Ihre Erben von der weiteren Wertsteigerung Ihres Anteils profitieren können.',
          },
        ]}
      />
      <Flow
        title="Wie funktioniert der Teilverkauf von Häusern?"
        titleID="wie-funktioniert-teilverkauf"
        items={[
          {
            title: 'Erstgespräch',
            description:
              'In einem Erstgespräch gehen wir auf Ihre höchstpersönlichen Wünsche ein und beraten Sie, ob ein Teilverkauf das Richtige ist. Auf Wunsch kommt unser Kundenberater auf ein persönliches Gespräch zu Ihnen.',
          },
          {
            title: 'Unverbindliches Angebot',
            description:
              'Wir lassen Ihnen ein erstes unverbindliches Angebot mit sämtlichen Konditionen zukommen.',
          },
          {
            title: 'Immobilienwertgutachten',
            description:
              'Ein unabhängiger Gutachter bewertet Ihre Immobilie um den genauen Verkehrswert zu ermitteln. Ihnen entstehen für das Gutachten keinerlei Kosten.',
          },
          {
            title: 'Angebot',
            description:
              'Für den Teilverkauf wird zunächst ein Kaufvertrag erstellt - genauso  wie bei einem normalen Immobilienverkauf. Eine Miteigentümervereinbarung sichert Verkäufer und Käufer rechtlich ab.',
          },
          {
            title: 'Vertragsunterzeichnung',
            description:
              'Der Vertrag inklusive der Miteigentümervereinbarung wird anschließend von allen Beteiligten unterzeichnet und notariell beglaubigt.',
          },
          {
            title: 'Finanzielle Freiheit genießen',
            description:
              'Ihnen wird der Netto-Kaufpreis für den verkauften Anteil der Immobilie ausbezahlt. Über dieses Kapital können Sie frei verfügen und Ihre Vorhaben verwirklichen.',
          },
        ]}
        hideAboveCTAText
        ctaButtonID="Teilverkauf--Flow"
        ctaText="Jetzt Beratungsgespräch vereinbaren"
        ctaExtraProps={{
          variant: 'v2Main1',
          width: ['100%', 400],
          sx: {
            maxWidth: 500,
            mx: ['auto', 0],
          },
        }}
        leadModalType="teilverkauf_callback"
        backgroundColor="white"
      />
      {REACT_APP_IS_TV_CALCULATOR_ENABLED === 'true' && (
        <TeilverkaufCalculator
          title={'Meine Auszahlung berechnen'}
          titleID="tv-berechnen"
        />
      )}
      <TVSituations />
      <FAQTeilverkauf
        title="Fragen und Antworten zum Teilverkauf"
        titleID="faq-teilverkauf"
        subheading="Hier finden Sie einige FAQs & Definitionen – einfach erklärt!"
      />
      <Testimonials />
      <ContactFullWidth
        title="Jetzt für einen kostenlosen Beratungstermin anfragen"
        email="beratung@bambus.io"
        phoneNumber={translate({
          DE: '+49 30 837 960 40',
          AT: '+43 1 435 0123',
          _: '+43 1 435 0123',
        })}
        gatsbyFluidImage={contactFullWidthImage.childImageSharp.fluid}
        textPinkButton="Jetzt Teilverkaufsangebot anfragen"
        directContactText={translate({
          DE: 'Gerne können Sie uns auch direkt kontaktieren:',
          AT: 'Kontaktieren Sie uns gerne:',
          _: 'Gerne können Sie uns auch direkt kontaktieren:',
        })}
        imagePosition={[
          '25px 0px',
          '100px 0px',
          '200px 0px',
          '240px 0px',
          '500px 40%',
        ]}
        ctaBlueButtonID="Teilverkauf--ContactFullWidth-Blue"
        leadModalTypeBlueButton="teilverkauf_callback"
        ctaPinkButtonID="Teilverkauf--ContactFullWidth-Pink"
        leadModalTypePinkButton="teilverkauf"
        imageTransform={['scale(1.2)', 'scale(1)', 'scale(1)']}
      />
      <Flow
        title="Flexible Finanzierung durch Teilverkauf und Partnerschaft mit Bambus"
        items={[
          {
            title: 'Vor dem Teilverkauf',
            description:
              'Sie sind alleiniger Eigentümer Ihres Eigenheims, können aber auf das Immobilienvermögen nicht zugreifen. So reicht beispielsweise für anfallende Ausgaben das Geld nicht aus, selbst wenn Sie alle Entscheidungen über Ihr Eigenheim alleine treffen können.',
            iconSrc: '/icons/tv_flow_2.svg',
          },
          {
            title: 'Partnerschaft',
            description:
              'Sie haben nun einen stillen Miteigentümer an Ihrer Seite und können flexibel auf Ihr Immobilienvermögen zugreifen. Sie können immer noch alle Entscheidungen alleine treffen und haben finanzielle Flexibilität und Freiheit. Wir verrechnen Ihnen für die Nutzung des verkauften Anteils eine klar definierte und faire Nutzungsgebühr.',
            iconSrc: '/icons/tv_flow_3.svg',
          },
          {
            title: 'Gemeinsamer Verkauf oder Rückkauf',
            description:
              'Sie haben jederzeit zwei Möglichkeiten, den Vertrag mit Bambus zu beenden: Den Rückkauf des verkauften Anteils oder den Gesamtverkauf der Immobilie.',
            iconSrc: '/icons/tv_flow_4.svg',
          },
        ]}
        hideAboveCTAText
        hideCTA
        noNumbering
      />
      <PageSnippets
        title="Erfahren Sie mehr über Bambus"
        snippets={[
          {
            id: 1,
            title: 'Top Beratung',
            description:
              'Gemeinsam finden wir Ihre optimale Finanzierungslösung',
            link: '/beratung',
            gatsbyFluidImage: salesImage.childImageSharp.fluid,
          },
          {
            id: 2,
            title: 'Über Bambus',
            description: 'Unsere Mission - unsere Vision - unsere Lösungen',
            link: '/team',
            gatsbyFluidImage: bambusHouseImage.childImageSharp.fluid,
          },
        ]}
        ctaBlueButtonID="Teilverkauf--ContactFullWidth-Blue"
        ctaPinkButtonID="Teilverkauf--ContactFullWidth-Pink"
      />
      <Blog
        title="Tauchen Sie in unseren Ratgeber ein"
        articles={
          countryCode === 'AT' ? featuredArticlesAT : featuredArticlesDE
        }
        subheadingElement={
          <Text textAlign="center" color="darkNavy80">
            Diese und weitere Artikel zum Thema Teilverkauf finden Sie in
            unserem{' '}
            <Link as={RouterLink} to="/ratgeber">
              Ratgeber
            </Link>
          </Text>
        }
      />
      <Map noPaddingTop />
    </Layout>
  )
}
export default Page
